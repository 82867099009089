import PropTypes from 'prop-types';
/* eslint-disable max-len */

const HomeIcon = ({
  dataTestId = 'homeIcon',
  viewBox = '0 0 30 30',
  height = '30',
  width = '30',
  fill,
}) => (
  <svg
    data-testid={dataTestId}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1556 17.5518H12.8479V24H17.1556V17.5518Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12.2759V24H23V12.2759L15.3077 7L7 12.2759Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
HomeIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default HomeIcon;
