import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cssVars from 'src/common/styles/variables';
import web from '../../../constants/analytics/categoryActionLabel/web';
import viewTypes from '../../../constants/auth/viewTypes';
import { SIGN_IN, SIGN_UP } from '../../../constants/localizations/auth';
import { LOGOUT, SETTINGS } from '../../../constants/localizations/header';
import { ACCOUNT_PATH } from '../../../constants/paths';
import LogoutIcon from '../../shared/svgIcons/LogoutIcon';
import SettingsIcon from '../../shared/svgIcons/SettingsIcon';
import SignInIcon from '../../shared/svgIcons/SignInIcon';
import SignUpIcon from '../../shared/svgIcons/SignUpIcon';
import css from '../leftSide.module.scss';

const { leftNav: leftNavLabels } = web.subLabels;

const iconProps = {
  fill: cssVars['--secondary-color-5'],
  width: cssVars['--large-icon-size'],
  height: cssVars['--large-icon-size'],
};

export function AuthedLinks({
  pathname,
  getLocalizedText,
  onMenuItemClick,
  logout,
}) {
  return (
    <>
      <div className={css.navigationMenuItemWrapper}>
        <Link
          data-testid="settings"
          className={classNames(css.navigationMenuItem, {
            [css.activeMenuItem]: pathname === ACCOUNT_PATH,
          })}
          to={ACCOUNT_PATH}
          onClick={() => onMenuItemClick(leftNavLabels.account)}
        >
          <div
            id="SettingsIconContainer"
            data-testid="SettingsIconContainer"
            className={css.navigationMenuIcon}
          >
            <SettingsIcon {...iconProps} />
          </div>
          {getLocalizedText(SETTINGS)}
        </Link>
      </div>
      <div className={css.navigationMenuItemWrapper}>
        <div
          data-testid="logout"
          className={css.navigationMenuItem}
          onClick={() => {
            onMenuItemClick(leftNavLabels.logout);
            logout();
          }}
        >
          <div
            id="LogoutIconContainer"
            data-testid="LogoutIconContainer"
            className={css.navigationMenuIcon}
          >
            <LogoutIcon {...iconProps} />
          </div>
          {getLocalizedText(LOGOUT)}
        </div>
      </div>
    </>
  );
}

AuthedLinks.propTypes = {
  pathname: PropTypes.string.isRequired,
  getLocalizedText: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export function UnauthedLinks({
  authActions,
  authLink,
  getLocalizedText,
  onMenuItemClick,
  useAuthDialogs,
}) {
  return (
    <>
      <div className={css.navigationMenuItemWrapper}>
        <div
          data-testid="sidebarSignIn"
          className={css.navigationMenuItem}
          onClick={() => {
            onMenuItemClick(leftNavLabels.signIn);

            if (useAuthDialogs) {
              authActions.needsSignInDialog();
            } else {
              authLink(viewTypes.signIn);
            }
          }}
        >
          <div
            id="SignInIconContainer"
            data-testid="SignInIconContainer"
            className={css.navigationMenuIcon}
          >
            <SignInIcon {...iconProps} />
          </div>
          {getLocalizedText(SIGN_IN)}
        </div>
      </div>
      <div className={css.navigationMenuItemWrapper}>
        <div
          data-testid="sidebarSignUp"
          className={css.navigationMenuItem}
          onClick={() => {
            onMenuItemClick(leftNavLabels.signUp);

            if (useAuthDialogs) {
              authActions.needsSignUpDialog();
            } else {
              authLink(viewTypes.signUp);
            }
          }}
        >
          <div
            id="SignUpIconContainer"
            data-testid="SignUpIconContainer"
            className={css.navigationMenuIcon}
          >
            <SignUpIcon {...iconProps} />
          </div>
          {getLocalizedText(SIGN_UP)}
        </div>
      </div>
    </>
  );
}

UnauthedLinks.propTypes = {
  authActions: PropTypes.shape({
    needsSignInDialog: PropTypes.func.isRequired,
    needsSignUpDialog: PropTypes.func.isRequired,
  }).isRequired,
  authLink: PropTypes.func.isRequired,
  getLocalizedText: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  useAuthDialogs: PropTypes.bool.isRequired,
};
