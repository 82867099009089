import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openUpsellAndTrackActivity } from '../../actions/dialog';
import { logSubscribeActivity } from '../../actions/logging';
import subscribe from '../../constants/analytics/categoryActionLabel/subscribe';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../../constants/experiments/dialog';
import { UPSELL_CTA_TEXT } from '../../constants/experiments/upsell';
import {
  UPSELL_GO_PREMIUM,
  UPSELL_START_FREE_TRIAL_BTN,
} from '../../constants/localizations/upsell';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectExperiment } from '../../selectors/config';
import createSubscribeLink from '../../utils/subscription/createSubscribeLink';
import InkButton from '../shared/button/InkButton';
import InkButtonLink from '../shared/button/InkButtonLink';
import createAndOpenSubscribeLink from '../utils/createAndOpenSubscribeLink';

const UpsellButton = ({
  source,
  dataTestId,
  guideId,
  isExpiredWebSubscriber,
  isPremiumUpsellBypassEnabled,
  actions,
}) => {
  const { getLocalizedText, location } = useContext(
    LocationAndLocalizationContext,
  );
  const upsellCtaText = useSelector((state) =>
    selectExperiment(state, UPSELL_CTA_TEXT),
  );
  const textKey = isExpiredWebSubscriber
    ? UPSELL_GO_PREMIUM
    : UPSELL_START_FREE_TRIAL_BTN;

  const handleClick = () => {
    if (isPremiumUpsellBypassEnabled) {
      createAndOpenSubscribeLink({ guideId }, location, null, source);
      return;
    }

    actions.openUpsellAndTrackActivity(source, guideId);
  };

  if (isExpiredWebSubscriber) {
    return (
      <InkButtonLink
        id={`subscribe-${dataTestId}`}
        dataTestId={dataTestId}
        to={createSubscribeLink({
          source,
          guideId,
          location,
        })}
        label={getLocalizedText(upsellCtaText || textKey)}
        onClick={() =>
          actions.logSubscribeActivity(
            subscribe.actions.tap,
            subscribe.labels.leftsidenavUpsell,
          )
        }
      />
    );
  }

  return (
    <InkButton
      id={dataTestId}
      dataTestId={dataTestId}
      label={getLocalizedText(upsellCtaText || textKey)}
      onClick={handleClick}
    />
  );
};

UpsellButton.propTypes = {
  source: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  guideId: PropTypes.string,
  isExpiredWebSubscriber: PropTypes.bool,
  isPremiumUpsellBypassEnabled: PropTypes.bool,
  actions: PropTypes.shape({
    openUpsellAndTrackActivity: PropTypes.func.isRequired,
    logSubscribeActivity: PropTypes.func.isRequired,
  }),
};

export function mapDispatchToProps(dispatch) {
  const actions = {
    openUpsellAndTrackActivity,
    logSubscribeActivity,
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    isPremiumUpsellBypassEnabled: selectExperiment(
      state,
      BYPASS_UPSELL_DIALOG_ENABLED,
      false,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpsellButton);
