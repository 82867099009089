import PropTypes from 'prop-types';
/* eslint-disable max-len */

const SettingsIcon = ({
  dataTestId = 'settingsIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2986_460)">
      <path
        d="M23.3721 12.7814H20.3498L22.4847 10.6465L19.3535 7.51537L17.2186 9.65026V6.62793H12.7814V9.65026L10.6465 7.51537L7.51537 10.6465L9.65026 12.7814H6.62793V17.2186H9.65026L7.51537 19.3535L10.6465 22.4847L12.7814 20.3498V23.3721H17.2186V20.3498L19.3535 22.4847L22.4847 19.3535L20.3498 17.2186H23.3721V12.7814Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.008 13.0325H14.9997C13.9131 13.0325 13.0322 13.9134 13.0322 15V15.0083C13.0322 16.0949 13.9131 16.9758 14.9997 16.9758H15.008C16.0946 16.9758 16.9755 16.0949 16.9755 15.0083V15C16.9755 13.9134 16.0946 13.0325 15.008 13.0325Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2986_460">
        <rect width="18" height="18" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
SettingsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default SettingsIcon;
