export const SEARCH_RESULTS_FOR = 'search.searchResultsFor';
export const SEARCH_CAPITALIZED = 'general.search.capitalized';
export const SEARCH_UPPERCASE = 'general.search.uppercase';
export const SEARCHBAR_PLACEHOLDER_LARGE = 'search.bar.placeholder.large';
export const SEARCHBAR_DISCORD_PLACEHOLDER_LARGE =
  'search.bar.discord.placeholder.large';
export const SEARCHBAR_PLACEHOLDER_SMALL = 'search.bar.placeholder.small';
export const RECENT_SEARCHES_HEADER = 'search.recents.header';
export const CLEAR_RECENT_SEARCHES_BUTTON = 'search.clear.recents';
export const SEARCH_PROMPT_HEADER = 'search.prompt.header';
export const SEARCH_PROMPT_BODY = 'search.prompt.body';
