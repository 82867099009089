import PropTypes from 'prop-types';
/* eslint-disable max-len */

const PodcastIcon = ({
  dataTestId = 'podcastIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9999 7.77765C17.9999 6.2436 16.6567 5 14.9999 5C13.343 5 11.9999 6.2436 11.9999 7.77765V15.5551C11.9999 17.0891 13.343 18.3327 14.9999 18.3327C16.6567 18.3327 17.9999 17.0891 17.9999 15.5551V7.77765Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M21 15.5557C21 17.029 20.3679 18.442 19.2426 19.4839C18.1174 20.5257 16.5913 21.111 15 21.111V21.111C13.4087 21.111 11.8826 20.5257 10.7574 19.4839C9.63214 18.442 9 17.029 9 15.5557"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M11.3999 25H18.5999"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M15 21.1104V24.9991"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
PodcastIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default PodcastIcon;
