import PropTypes from 'prop-types';
/* eslint-disable max-len */

const LibraryIcon = ({
  dataTestId = 'libraryIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8179 9.99902H6V22.999H10.8179V9.99902Z"
      stroke={fill}
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15.6364 7H10.8185V22.9999H15.6364V7Z"
      stroke={fill}
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M21.0073 10.1151L16.3535 11.4092L19.3463 23.0002L24 21.7061L21.0073 10.1151Z"
      stroke={fill}
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </svg>
);
LibraryIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default LibraryIcon;
