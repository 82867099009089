import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { logWebActivity } from 'src/common/actions/logging';
import { DOWNLOAD_DESKTOP_BUTTON } from 'src/common/constants/localizations/desktop';
import cssVars from 'src/common/styles/variables';
import web from '../../../constants/analytics/categoryActionLabel/web';
import useActions from '../../../hooks/useActions';
import { useDesktopDownloadLink } from '../../../hooks/useDesktopDownloadLink';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectUserAgent } from '../../../selectors/app';
import isMacDesktop from '../../../utils/userAgent/isMacDesktop';
import isWindowsDesktop from '../../../utils/userAgent/isWindowsDesktop';
import ExternalLink from '../../shared/link/ExternalLink';
import DownloadIcon from '../../shared/svgIcons/DownloadIcon';
import css from '../leftSide.module.scss';

const iconProps = {
  fill: cssVars['--secondary-color-5'],
  width: cssVars['--large-icon-size'],
  height: cssVars['--large-icon-size'],
};

export default function SidebarDesktopDownloadLink() {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const userAgent = useSelector(selectUserAgent);
  const actions = useActions({ logWebActivity });
  const { isDesktop, downloadLink } = useDesktopDownloadLink();

  if (isDesktop) {
    return null;
  }

  const renderChildren = (
    <>
      <div
        id="DownloadIconContainer"
        data-testid="DownloadIconContainer"
        className={css.navigationMenuIcon}
      >
        <DownloadIcon {...iconProps} />
      </div>
      {getLocalizedText(DOWNLOAD_DESKTOP_BUTTON)}
    </>
  );

  if (isMacDesktop(userAgent)) {
    return (
      <div
        id="macDesktopLinkContainer"
        data-testid="macDesktopLinkContainer"
        className={css.desktopLinkContainer}
      >
        <ExternalLink
          href={downloadLink}
          className={css.navigationMenuItem}
          data-testid="leftSideMacDesktopLink"
          onClick={() =>
            actions.logWebActivity(web.actions.tap, web.labels.macDownloadNav)
          }
        >
          {renderChildren}
        </ExternalLink>
      </div>
    );
  }

  if (isWindowsDesktop(userAgent)) {
    return (
      <div
        id="windowsDesktopLinkContainer"
        data-testid="windowsDesktopLinkContainer"
        className={css.desktopLinkContainer}
      >
        <ExternalLink
          href={downloadLink}
          className={css.navigationMenuItem}
          data-testid="leftSideWindowsDesktopLink"
          onClick={() =>
            actions.logWebActivity(
              web.actions.tap,
              web.labels.windowsDownloadNav,
            )
          }
        >
          {renderChildren}
        </ExternalLink>
      </div>
    );
  }

  return null;
}
