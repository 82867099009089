import PropTypes from 'prop-types';
/* eslint-disable max-len */

const PremiumIcon = ({
  dataTestId = 'premiumIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    data-testid={dataTestId}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.9933 13.0032L19.1927 17.9566L21.8073 26L14.9933 21.0332L8.16596 26L10.7805 17.9566L4 13.0032H12.3854L15 5L17.6012 13.0032H26H25.9933Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinejoin="bevel"
    />
  </svg>
);
PremiumIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default PremiumIcon;
