import PropTypes from 'prop-types';
/* eslint-disable max-len */

const AudiobooksIcon = ({
  dataTestId = 'audiobooksIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_558_1828)">
      <path
        d="M21.4108 8.63672C22.5027 8.72434 23.454 8.89959 24.1892 9.11865V23.8941C23.0648 23.5546 21.4216 23.3355 19.5946 23.3355C17.7675 23.3355 16.1243 23.5546 15 23.8941C13.8757 23.5546 12.2324 23.3355 10.4054 23.3355C8.57836 23.3355 6.93512 23.5546 5.81079 23.8941V9.11865C6.54593 8.89959 7.50809 8.72434 8.58917 8.63672"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.3028 22.9626C16.0055 21.9111 17.8757 21.1554 20.0703 21.1554C20.3622 21.1554 20.6433 21.1663 20.9136 21.1992V5.8651C20.6433 5.84319 20.3622 5.82129 20.0703 5.82129C17.2703 5.82129 15.0109 7.04801 15.0109 8.55951V22.8859"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.6972 22.9626C13.9945 21.9111 12.1243 21.1554 9.92967 21.1554C9.63778 21.1554 9.3567 21.1663 9.08643 21.1992V5.8651C9.3567 5.84319 9.63778 5.82129 9.92967 5.82129C12.7297 5.82129 14.9891 7.04801 14.9891 8.55951V22.8859"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_558_1828">
        <rect width="20" height="20" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);
AudiobooksIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default AudiobooksIcon;
