import PropTypes from 'prop-types';
/* eslint-disable max-len */

const DownloadIcon = ({
  dataTestId = 'downloadIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3 14.4375L15 17.2499L17.7 14.4375"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.9999 17.2497V6"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M24 17.25V23.9998H6V17.25"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
DownloadIcon.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default DownloadIcon;
