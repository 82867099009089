import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import pathsToGuideIdsData from '../../../data/rootCategories.json';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { Badge } from '../shared/Badge';
import css from './leftSide.module.scss';

const { pathsToGuideIds } = pathsToGuideIdsData;

export default function NavigationMenuItem({
  item,
  isSelected,
  subscriptionEnabled,
  onClick,
}) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  const showItem =
    item.guideId !== pathsToGuideIds.premium || subscriptionEnabled;

  const iconComponent = item?.iconComponent;
  const navMenuLineItem = (
    <>
      {iconComponent && (
        <div
          id={`${item.dataTestId}IconContainer`}
          data-testid={`${item.dataTestId}IconContainer`}
          className={css.navigationMenuIcon}
        >
          {iconComponent}
        </div>
      )}
      {getLocalizedText(item.textKey)}
      {!!item.badge && (
        <Badge {...item.badge.props}>
          {getLocalizedText(item.badge.textKey)}
        </Badge>
      )}
    </>
  );

  if (!showItem) {
    return null;
  }

  return (
    <div className={css.navigationMenuItemWrapper}>
      {item.path ? (
        <Link
          key={`${item.dataTestId}ListItem`}
          data-testid={`${item.dataTestId}MenuItem`}
          className={classNames(css.navigationMenuItem, {
            [css.activeMenuItem]: isSelected,
          })}
          to={item.path}
          onClick={() => onClick(item.guideId)}
          data-nextguideitem={item.guideId}
        >
          {navMenuLineItem}
        </Link>
      ) : (
        <div
          key={`${item.dataTestId}ListItem`}
          data-testid={`${item.dataTestId}MenuItem`}
          className={classNames(css.navigationMenuItem, {
            [css.activeMenuItem]: isSelected,
          })}
          onClick={() => item.onClick()}
          data-nextguideitem={item.guideId}
        >
          {navMenuLineItem}
        </div>
      )}
    </div>
  );
}

NavigationMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  subscriptionEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
