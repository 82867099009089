import classNames from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PAYMENT_COMPLETE_PATH, ROOT_PATH } from 'src/common/constants/paths';
import { selectIsBranchBannerShowing } from '../../../selectors/app';
import getLoginLocationDescriptor from '../../../utils/auth/getLoginLocationDescriptor';
import { isLarge } from '../../../utils/breakpoints';
import debounce from '../../../utils/debounce';
import shouldShowPlayer from '../../../utils/playerStatus/shouldShowPlayer';
import css from '../leftSide.module.scss';
import { AuthedLinks, UnauthedLinks } from './AuthStateLinks';
import SidebarDesktopDownloadLink from './SidebarDesktopDownloadLink';

const BottomMenu = forwardRef(
  (
    {
      history,
      getLocalizedText,
      location,
      breakpoint,
      isDesktop,
      isAuthenticated,
      isScrollShadowVisible,
      isFixedBottomMenuEnabled,
      isLeftSideOpen,
      authActions,
      onMenuItemClick,
    },
    ref,
  ) => {
    const { pathname } = location;
    const isPlayerVisible = useSelector(shouldShowPlayer);
    const isBranchBannerShowing = useSelector(selectIsBranchBannerShowing);
    const handleLogout = useMemo(
      () =>
        debounce(() => {
          authActions.logout();
          if (pathname === PAYMENT_COMPLETE_PATH) {
            history.push(ROOT_PATH);
          }
        }),
      [authActions, pathname, history],
    );
    const authLink = useMemo(
      () =>
        debounce((vt) => {
          authActions.updateAuthViewType(vt);
          history.push(getLoginLocationDescriptor(location, vt));
        }),
      [authActions, history, location],
    );
    const useAuthDialogs = isLarge(breakpoint) && !isDesktop;

    return (
      <div
        ref={ref}
        data-testid="bottomMenu"
        className={classNames(css.bottomMenu, {
          [css.fixedToBottom]: isFixedBottomMenuEnabled && isLeftSideOpen,
          [css.playerVisible]: isPlayerVisible,
          [css.scrollShadow]: isScrollShadowVisible,
          [css.branchBannerVisible]: isBranchBannerShowing,
        })}
      >
        {isAuthenticated ? (
          <AuthedLinks
            pathname={pathname}
            getLocalizedText={getLocalizedText}
            onMenuItemClick={onMenuItemClick}
            logout={() => handleLogout()}
          />
        ) : (
          <UnauthedLinks
            authActions={authActions}
            authLink={authLink}
            getLocalizedText={getLocalizedText}
            onMenuItemClick={onMenuItemClick}
            useAuthDialogs={useAuthDialogs}
          />
        )}
        <SidebarDesktopDownloadLink />
      </div>
    );
  },
);

BottomMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  getLocalizedText: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  breakpoint: PropTypes.number.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isScrollShadowVisible: PropTypes.bool.isRequired,
  isFixedBottomMenuEnabled: PropTypes.bool.isRequired,
  isLeftSideOpen: PropTypes.bool.isRequired,
  authActions: PropTypes.shape({
    logout: PropTypes.func.isRequired,
    updateAuthViewType: PropTypes.func.isRequired,
  }),
  onMenuItemClick: PropTypes.func.isRequired,
};

export default BottomMenu;
