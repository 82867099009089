import PropTypes from 'prop-types';
/* eslint-disable max-len */

const SportsIcon = ({
  dataTestId = 'sportsIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2235 21.4163C23.1369 19.4998 24.378 17.0155 24.7601 14.3372C25.1423 11.6589 24.6448 8.93134 23.3425 6.56483C20.9765 5.26238 18.2492 4.76522 15.5714 5.14824C12.8936 5.53125 10.4103 6.77369 8.49555 8.68841C6.58083 10.6031 5.33839 13.0864 4.95538 15.7642C4.57237 18.4421 5.06953 21.1694 6.37197 23.5354C8.73799 24.8395 11.466 25.338 14.1447 24.9558C16.8235 24.5736 19.3079 23.3313 21.2235 21.4163V21.4163Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M10.0085 19.8986L19.706 10.2012"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.8642 10.208L19.6993 15.0431"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12.4398 12.6328L17.2749 17.4679"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M10.0154 15.0576L14.8505 19.8927"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
SportsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default SportsIcon;
