import PropTypes from 'prop-types';
/* eslint-disable max-len */

const MusicIcon = ({
  dataTestId = 'musicIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 23.125C11 22.0895 10.1046 21.25 8.99999 21.25C7.89543 21.25 7 22.0895 7 23.125C7 24.1606 7.89543 25.0001 8.99999 25.0001C10.1046 25.0001 11 24.1606 11 23.125Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M23 19.375C23 18.3395 22.1046 17.5 21 17.5C19.8954 17.5 19 18.3395 19 19.375C19 20.4106 19.8954 21.2501 21 21.2501C22.1046 21.2501 23 20.4106 23 19.375Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M23 5V19.3752"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11 22.8752V8.5"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M23 10.0001L11 13.7501V8.75005L23 5V10.0001Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
MusicIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default MusicIcon;
