import PropTypes from 'prop-types';
/* eslint-disable max-len */

const ExplorerIcon = ({
  dataTestId = 'explorerIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    data-testid={dataTestId}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1765 12.4118C23.1765 18.2353 15.0882 26 15.0882 26C15.0882 26 7 18.2353 7 12.4118C7 7.76608 10.6212 4 15.0882 4C19.5552 4 23.1765 7.76608 23.1765 12.4118Z"
      stroke={fill}
      strokeWidth="1.5"
    />
    <rect
      x="10.3647"
      y="9.88795"
      width="1.33702"
      height="5.34807"
      rx="0.668509"
      fill={fill}
    />
    <rect
      x="13.0388"
      y="7.88235"
      width="1.33702"
      height="9.35912"
      rx="0.668509"
      fill={fill}
    />
    <rect
      x="15.7128"
      y="7.88235"
      width="1.33702"
      height="10.6961"
      rx="0.668509"
      fill={fill}
    />
    <rect
      x="18.3868"
      y="9.88795"
      width="1.33702"
      height="6.68509"
      rx="0.668509"
      fill={fill}
    />
  </svg>
);

ExplorerIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default ExplorerIcon;
