import PropTypes from 'prop-types';
/* eslint-disable max-len */

const TrendingIcon = ({
  dataTestId = 'trendingIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7915 14.1727L21.8751 9.84766L17.7404 9.93516"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M21.8753 9.84766L16.6651 15.2977L14.6336 13.1727L9.71021 18.3352"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M24.12 25H5V5"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
TrendingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default TrendingIcon;
