import PropTypes from 'prop-types';
/* eslint-disable max-len */

const LogoutIcon = ({
  dataTestId = 'logoutIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3062_12342)">
      <path
        d="M9.93555 17.4686L7.36412 15L9.93555 12.5314"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.36468 15H17.6504"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.28613 6.77143H22.4576V23.2286H6.28613"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3062_12342">
        <rect
          width="18"
          height="17.2286"
          fill="white"
          transform="translate(6 24) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
LogoutIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default LogoutIcon;
