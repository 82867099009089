import PropTypes from 'prop-types';
/* eslint-disable max-len */

const SignUpIcon = ({
  dataTestId = 'signUpIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 23.6192V20.9432C6 19.7218 6.975 18.7225 8.16667 18.7225H16.8333C18.025 18.7225 19 19.7218 19 20.9432V23.6192"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12.4997 14.7724C14.5938 14.7724 16.2913 13.0325 16.2913 10.8862C16.2913 8.73991 14.5938 7 12.4997 7C10.4056 7 8.70801 8.73991 8.70801 10.8862C8.70801 13.0325 10.4056 14.7724 12.4997 14.7724Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.25 12.75V7.75"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M19.75 10.25H24.75"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
SignUpIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default SignUpIcon;
