import PropTypes from 'prop-types';
/* eslint-disable max-len */

const IHeartIcon = ({
  dataTestId = 'IHeartIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 17.5599C16.1572 17.5599 17.0953 16.5656 17.0953 15.339C17.0953 14.1125 16.1572 13.1182 15 13.1182C13.8429 13.1182 12.9048 14.1125 12.9048 15.339C12.9048 16.5656 13.8429 17.5599 15 17.5599Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9971 7.0111C17.9124 7.0111 16.0686 8.14374 15 9.85379C13.921 8.14374 12.0876 7.0111 10.0029 7.0111C6.6819 7.0111 4 9.85379 4 13.3627C4 15.2505 4.77524 16.9272 6.00095 18.0932L12.9048 25V21.4355C12.9048 20.8248 13.3762 20.3251 13.9524 20.3251H16.0476C16.6238 20.3251 17.0952 20.8248 17.0952 21.4355V25L23.999 18.0932C25.2248 16.9272 26 15.2394 26 13.3627C26 9.85379 23.3181 7 19.9971 7V7.0111Z"
      stroke={fill}
      strokeWidth="1.43"
      strokeLinejoin="round"
    />
  </svg>
);
IHeartIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default IHeartIcon;
