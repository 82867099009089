import classNames from 'clsx';
import PropTypes from 'prop-types';

import PillButtonLink from './PillButtonLink';

import css from './pill-button.module.scss';

const InkButtonLink = ({ className, ...otherProps }) => (
  <PillButtonLink className={classNames(className, css.ink)} {...otherProps} />
);

InkButtonLink.propTypes = {
  className: PropTypes.string,
};

export default InkButtonLink;
