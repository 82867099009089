import PropTypes from 'prop-types';
/* eslint-disable max-len */

const RadioIcon = ({
  dataTestId = 'radioIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9583 19.2347C21.7177 19.2347 22.3333 18.6026 22.3333 17.8229C22.3333 17.0432 21.7177 16.4111 20.9583 16.4111C20.1989 16.4111 19.5833 17.0432 19.5833 17.8229C19.5833 18.6026 20.1989 19.2347 20.9583 19.2347Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.9583 13.5882C21.7177 13.5882 22.3333 12.9561 22.3333 12.1764C22.3333 11.3967 21.7177 10.7646 20.9583 10.7646C20.1989 10.7646 19.5833 11.3967 19.5833 12.1764C19.5833 12.9561 20.1989 13.5882 20.9583 13.5882Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.1458 10.7646H7.66663"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M16.1458 16.4111H7.66663"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M16.1458 13.5889H7.66663"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M16.1458 19.2344H7.66663"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M26 7H4V23H26V7Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
RadioIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default RadioIcon;
