import PropTypes from 'prop-types';
/* eslint-disable max-len */

const LanguageIcon = ({
  dataTestId = 'languageIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 15H25" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M15 5V25" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M8.1001 7.77832C9.95225 9.56492 12.4267 10.5611 15.0001 10.5561C17.5889 10.5727 20.0813 9.57567 21.9445 7.77832"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M21.9445 22.2228C20.0848 20.4499 17.6139 19.4609 15.0445 19.4609C12.4751 19.4609 10.0043 20.4499 8.14453 22.2228"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15 25C20.5228 25 25 20.5228 25 15C25 9.47715 20.5228 5 15 5C9.47715 5 5 9.47715 5 15C5 20.5228 9.47715 25 15 25Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15 25C17.7614 25 20 20.5228 20 15C20 9.47715 17.7614 5 15 5C12.2386 5 10 9.47715 10 15C10 20.5228 12.2386 25 15 25Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
LanguageIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default LanguageIcon;
